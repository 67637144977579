import React, { useState, useEffect } from 'react';
import { Fragments, booleanFilter } from 'common';
import { Dropdown, Icon, Typography } from 'components';
import TopicItem from '../../containers/MediathekHome/TopicItem/TopicItem';
import { useSearchForTopicsLazyQuery } from '../../graphql/catalog/queries';
import { getTopicsSearchQuery } from '../../containers/MediathekHome/MediathekHome.utils';
import { TopicsProps } from '../../containers/MediathekHome/Topics.types';
import { useTopicParam, TopicBreadcrumbItem } from '../../utils/topics';
import useStyles from './TopicsHeader.styles';

const TopicsHeaderDropdown = (props: TopicsProps) => {
	const { onChange, variant, className } = props;
	const { topics, loading } = useTopicParam();
	const [topicsData, setTopicsData] = useState<Array<Array<Fragments.TopicFieldsFragment>>>([]);
	const classes = useStyles();
	const isMulti = variant === 'multi-dropdown';

	const [fetchTopics] = useSearchForTopicsLazyQuery();

	useEffect(() => {
		/**
		 * this effect is responsible for fetching topics
		 * if selected topics is AM,KARDI
		 * there will be 3 fetches:
		 * 1. [0,1] level topics with AM being selected
		 * 2. topics with parent AM with KARDI being selected
		 * 3. topics with parent KARDI which have not a pre-selected item
		 */
		if (!loading) {
			setTopicsData([]);
			const topicFilters = [
				getTopicsSearchQuery({
					pageNumber: 0,
					pageSize: null,
					levels: ['0', '1']
				})
			];
			if (isMulti) {
				topics.map((each) => {
					topicFilters.push(
						getTopicsSearchQuery({
							pageNumber: 0,
							pageSize: null,
							parentCode: each.code
						})
					);
				});
			}
			topicFilters.forEach(async (each) => {
				const { data } = await fetchTopics({ variables: each });
				setTopicsData((curr) => {
					if (data?.topicDocuments.data && data?.topicDocuments.data.length) {
						return [...curr, data?.topicDocuments.data?.filter(booleanFilter)];
					}

					return curr;
				});
			});
		}
	}, [topics, variant, loading]);

	const handleSelectTopic = (index?: number) => (topic: TopicBreadcrumbItem | null) => {
		onChange(topic, { index });
	};

	return (
		<>
			{topicsData.map((currentTopics, idx) => {
				let selectedValue = null;
				if (isMulti) {
					selectedValue = topics[idx]?.code;
				} else {
					selectedValue = topics[topics.length - 1]?.code;
				}

				return (
					<Dropdown
						className={className}
						key={idx}
						options={[
							{
								id: '',
								localeId:
									idx === 0
										? 'catalog.topic-dropdown.all-topics'
										: 'catalog.topic-dropdown.all-subtopics'
							},
							...currentTopics?.map((each) => ({
								id: each.code,
								label: (
									<TopicItem
										topic={each}
										onClick={handleSelectTopic(idx)}
										variant={variant}
										isDropdown
									/>
								)
							}))
						]}
						value={selectedValue}
						title={
							<div className={classes.titleContainer}>
								<Icon className={classes.image} icon="apps" />
								<Typography
									className={classes.title}
									localeId={
										idx === 0
											? 'catalog.topic-dropdown.placeholder.first'
											: 'catalog.topic-dropdown.placeholder.second'
									}
									variant="textSmall"
								/>
							</div>
						}
						onChange={(value) => {
							const topic = currentTopics?.find((each) => each.code === value);
							handleSelectTopic(idx)(topic ?? null);
						}}
					/>
				);
			})}
		</>
	);
};

export default TopicsHeaderDropdown;
