import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
	image: {
		objectFit: 'cover',
		color: theme.palette.common.black,
		width: 24,
		height: 24,
		marginRight: theme.spacing(2)
	},
	titleContainer: {
		display: 'flex',
		height: '100%',
		alignItems: 'center'
	},
	title: {
		textAlign: 'center',
		wordBreak: 'break-word',
		color: theme.palette.common.black,
		opacity: 0.6,
		fontWeight: 400
	}
}));

export default useStyles;
