import { useState, useEffect } from 'react';
import { Fragments, Router, Types, useRouter } from 'common';
import {
	SearchProductQueryParams,
	LectureSourceOptionEnum,
	SortingProductEnum,
	ContentContentTypeFilterType,
	FilterProps,
	FiltersLanguage,
	CONTENT_TYPE_QUERY_PARAM_NAME,
	DocumentsCountByLanguage
} from './Filters.types';
import _isEqual from 'lodash/isEqual';
import { useTopicParam, TopicBreadcrumbItem } from '../../utils/topics';
import { DateFilterOptionEnum } from '../../utils/filters/dateFilter';

type Options = {
	shouldPersistAtEnd?: boolean;
};

const useFilters = (onChange?: FilterProps['onChange'], options?: Options) => {
	const { shouldPersistAtEnd = false } = options ?? {};
	const router = useRouter<SearchProductQueryParams>();
	const { query: queryParams, setQuery } = router;
	const { lastTopicCode: topicCode, updateTopicParam, topics } = useTopicParam();
	const [stateQueryParams, setStateQueryParams] = useState(queryParams);
	const [stateTopics, setStateTopics] = useState<Array<Fragments._TopicFieldsFragment>>([]);
	const [hasChanged, setHasChanged] = useState(false);

	// make sure the state is up to date with query params
	useEffect(() => {
		if (!hasChanged && !_isEqual(queryParams, stateQueryParams) && shouldPersistAtEnd) {
			setStateQueryParams(queryParams);
		}
	}, [hasChanged, queryParams, shouldPersistAtEnd]);

	useEffect(() => {
		if (!hasChanged && !_isEqual(topics, stateTopics) && shouldPersistAtEnd) {
			setStateTopics(topics);
		}
	}, [hasChanged, topics, shouldPersistAtEnd]);

	const persistStateQueryParams = () => {
		if (hasChanged) {
			handleContentTypeChange(stateQueryParams.contentTypeFilter, true);
			handleDateChange(stateQueryParams.date, true);
			handleSourceChange(stateQueryParams.lectureSource, true);
			handleSortingChange(stateQueryParams.sortBy, true);
			handleChangeLanguage(stateQueryParams.language, true);
			updateTopicParam(stateTopics);
			setHasChanged(false);
		}
	};

	const clearStateQueryParams = () => {
		setHasChanged(false);
		setStateQueryParams(queryParams);
		setStateTopics([]);
	};

	const resetQueryParams = (
		newParams?: Router<SearchProductQueryParams>['query'],
		forceUpdate?: boolean
	) => {
		if (onChange) {
			onChange();
		}
		if (shouldPersistAtEnd && newParams && !forceUpdate) {
			setHasChanged(true);
			setStateQueryParams({ ...stateQueryParams, ...newParams });
		} else {
			if (newParams) {
				setQuery(newParams);
				setStateQueryParams(newParams);
			}
		}
	};
	const resetAllFilters = () => {
		clearStateQueryParams();
		resetQueryParams(
			{
				[CONTENT_TYPE_QUERY_PARAM_NAME]: null,
				search: null,
				date: null,
				lectureSource: null,
				sortBy: null,
				language: null
			},
			true
		);
		updateTopicParam([]);
	};

	const handleContentTypeChange = (newContentType?: string | null, forceUpdate?: boolean) => {
		const newQueryParams: SearchProductQueryParams = {};
		if (newContentType) {
			newQueryParams.contentTypeFilter = newContentType as ContentContentTypeFilterType;
		}
		if (
			newContentType === Types.ContentType.Course ||
			newContentType === Types.ContentType.Webinar
		) {
			newQueryParams.date = DateFilterOptionEnum.FORTHCOMING;
		} else {
			newQueryParams.date = null;
		}
		if (newContentType !== Types.ContentType.Lecture) {
			newQueryParams.lectureSource = null;
		}
		if (newContentType) {
			resetQueryParams(newQueryParams, forceUpdate);
		}
	};

	const handleDateChange = (dateFilterOption?: string | null, forceUpdate?: boolean) => {
		resetQueryParams({ date: dateFilterOption as DateFilterOptionEnum }, forceUpdate);
	};

	const handleSourceChange = (lectureSourceOption?: string | null, forceUpdate?: boolean) => {
		lectureSourceOption &&
			resetQueryParams(
				{ lectureSource: lectureSourceOption as LectureSourceOptionEnum },
				forceUpdate
			);
	};

	const handleSortingChange = (newSortingOption?: string | null, forceUpdate?: boolean) => {
		newSortingOption &&
			resetQueryParams({ sortBy: newSortingOption as SortingProductEnum }, forceUpdate);
	};

	const setAllTopics = (newTopics: Array<Fragments._TopicFieldsFragment>) => {
		onChange?.();
		if (shouldPersistAtEnd) {
			setHasChanged(true);
			setStateTopics(newTopics);
		} else {
			updateTopicParam(newTopics);
		}
	};

	const handleTopicChange = (topic: TopicBreadcrumbItem | null, options?: { index?: number }) => {
		if (onChange) {
			onChange();
		}
		updateTopicParam(topic, options);
	};

	const removeFilter = (removableKey: string) => {
		const newParams = Object.entries(queryParams)
			.map(([key, value]) => {
				if (key === removableKey) {
					return { key, value: null };
				}
				return { key, value };
			})
			.reduce((prev, { key, value }) => ({ ...prev, [key]: value }), {});
		resetQueryParams(newParams);
	};

	const handleChangeLanguage = (newLanguage?: string | null, forceUpdate?: boolean) => {
		const lang = newLanguage === 'ALL' ? null : (newLanguage as FiltersLanguage);
		resetQueryParams({ language: lang }, forceUpdate);
	};

	return {
		queryParams,
		stateQueryParams,
		topicCode,
		handleContentTypeChange,
		handleDateChange,
		handleSourceChange,
		handleSortingChange,
		handleTopicChange,
		handleChangeLanguage,
		persist: persistStateQueryParams,
		clear: clearStateQueryParams,
		setAllTopics,
		removeFilter,
		topics,
		resetAllFilters
	};
};

type DomainLanguageOption = {
	localeId: string;
	id: 'ALL' | Language;
};

const getDomainLanguageOptions = (countryCode: CountryCode): Array<DomainLanguageOption> => {
	return [
		{ localeId: 'filters.language.all', id: 'ALL' as const },
		{ localeId: 'filters.language.german', id: 'de' },
		...(countryCode === 'ch'
			? [{ localeId: 'filters.language.french', id: 'fr' as const }]
			: []),
		{ localeId: 'filters.language.english', id: 'en' }
	];
};

const filterAvailableLanguageOptions = (
	options: Array<DomainLanguageOption>,
	countByLanguage: DocumentsCountByLanguage
): Array<DomainLanguageOption> => {
	return options.filter((opt) => (opt.id === 'ALL' ? true : countByLanguage[opt.id] > 0));
};

export default {
	useFilters,
	getDomainLanguageOptions,
	filterAvailableLanguageOptions
};
