import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
	filter: {
		width: '100%',
		marginBottom: theme.spacing(1)
	},
	topicDropdown: {
		width: '100%'
	},
	title: {
		fontWeight: 600
	},
	topicString: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		whiteSpace: 'nowrap',
		fontWeight: 400,
		paddingRight: 16
	},
	topicButton: {
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: theme.spacing(-2),
		padding: theme.spacing(2, 0)
	},
	backArrow: {
		marginRight: theme.spacing(2)
	},
	backButton: {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		marginBottom: theme.spacing(-2),
		padding: theme.spacing(2, 0)
	},
	divider: {
		margin: theme.spacing(2, 0)
	},
	topicItem: {
		margin: theme.spacing(0, -1.5),
		padding: theme.spacing(1.5),
		cursor: 'pointer',
		borderRadius: 6,
		'&:hover': {
			backgroundColor: theme.palette.secondary['100']
		}
	},
	topicSeparator: {
		margin: theme.spacing(0, 1)
	}
}));

export default useStyles;
