import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
	(theme) => ({
		container: {
			width: '100%',
			minWidth: 124,
			display: 'flex',
			alignItems: 'center',

			'&$horizontalList': {
				flexDirection: 'column',
				height: '100%',
				padding: theme.spacing(2, 0),

				transition: 'background-color 0.1s',

				'&:hover': {
					borderRadius: 6,
					backgroundColor: `#0000000A`
				},
				'&$active': {
					...theme.border({
						width: 1,
						radius: 6,
						color: theme.palette.secondary.main
					}),
					backgroundColor: `${theme.palette.secondary.main}0A`
				}
			}
		},
		image: {
			objectFit: 'cover',
			color: theme.palette.secondary.main,
			marginLeft: theme.spacing(2),
			'&$horizontalList': {
				marginLeft: 0
			}
		},
		title: {
			marginLeft: theme.spacing(2),
			textAlign: 'center',
			wordBreak: 'break-word',
			fontSize: 15,
			color: theme.palette.titleActive.main,
			fontWeight: 600,
			'&$horizontalList': {
				marginTop: theme.spacing(1),
				marginLeft: 0
			}
		},
		selectable: {
			cursor: 'pointer'
		},
		active: {},
		dropdownSelectable: {
			cursor: 'pointer'
		},
		horizontalList: {}
	}),
	{ name: 'TopicItem' }
);

export default useStyles;
