import React, { useState, useEffect } from 'react';
import { Typography, Icon } from 'components';
import { Fragments, formatters, booleanFilter } from 'common';
import { Divider } from '@mui/material';
import { useIntl } from 'react-intl';
import { TopicsSliderProps } from './TopicsSlider.types';
import {
	SearchForTopicsQueryVariables,
	useSearchForTopicsQuery
} from '../../graphql/catalog/queries';
import useStyles from './TopicsSlider.styles';
import { FilterUtils } from '../Filters';
import { getTopicsSearchQuery } from '../../containers/MediathekHome/MediathekHome.utils';

const generateTopicVariables = (
	parents: Array<Fragments.TopicFieldsFragment | null>
): [SearchForTopicsQueryVariables | null, SearchForTopicsQueryVariables] => {
	const lastParent = parents[parents.length - 1];
	const parentFilter = lastParent
		? getTopicsSearchQuery({
				pageNumber: 0,
				pageSize: null,
				filters: [{ field: 'code', value: [lastParent.code] }]
			})
		: null;

	return [
		parentFilter,
		getTopicsSearchQuery({
			pageSize: null,
			pageNumber: 0,
			...(lastParent ? { parentCode: lastParent.code } : { levels: ['0', '1'] })
		})
	];
};

const TopicsSlider = ({ children, onChange }: TopicsSliderProps) => {
	const classes = useStyles();
	const [parents, setParents] = useState<Array<Fragments.TopicFieldsFragment | null>>([]);
	const [showTopics, setShowTopics] = useState(false);
	const { locale, formatMessage } = useIntl();
	const { topics } = FilterUtils.useFilters();
	const [parentFilter, childrenFilter] = generateTopicVariables(parents);

	useEffect(() => {
		setParents(topics);
	}, [topics]);
	const { data: parentData } = useSearchForTopicsQuery({
		variables: parentFilter || undefined,
		skip: !parentFilter
	});
	const { data: childrenData } = useSearchForTopicsQuery({ variables: childrenFilter });
	if (showTopics) {
		const parentTopic = parentData?.topicDocuments?.data?.[0];
		const onBackClick = () => {
			if (parents.length === 1) {
				setShowTopics(false);
				setParents([]);
				onChange([]);
			} else {
				const newParents = parents.slice(0, parents.length - 1).filter(booleanFilter);
				setParents(newParents);
				onChange(newParents);
				if (newParents.length === 0) {
					setShowTopics(false);
				}
			}
		};
		return (
			<div>
				<div onClick={onBackClick} className={classes.backButton}>
					<Icon icon="arrowLeft" className={classes.backArrow} />
					{parentTopic ? (
						<Typography
							className={classes.title}
							title={formatters.formatTranslation(parentTopic.description, {
								locale
							})}
							fontFamily="Poppins"
						/>
					) : (
						<Typography
							localeId="catalog.search-media.topic"
							className={classes.title}
						/>
					)}
				</div>
				<Divider className={classes.divider} />
				{childrenData?.topicDocuments?.data?.map((each) => (
					<div
						key={each.id}
						className={classes.topicItem}
						onClick={() => {
							const newParents = [...parents, each];
							setParents(newParents);
							onChange(newParents.filter(booleanFilter));
						}}
					>
						<Typography
							title={formatters.formatTranslation(each.description, { locale })}
							fontFamily="Poppins"
							variant="textXSmall"
						/>
					</div>
				))}
			</div>
		);
	}

	return (
		<div>
			<div onClick={() => setShowTopics(true)} className={classes.topicButton}>
				<div>
					<Typography localeId="catalog.search-media.topic" className={classes.title} />
					<Typography className={classes.topicString} color="secondary">
						{parents.map((each) => (
							<>
								{formatters.formatTranslation(each?.description, { locale })}
								<Icon icon="caretRight" className={classes.topicSeparator} />
							</>
						))}
						{formatMessage({ id: 'catalog.search.topic.all-items' })}
					</Typography>
				</div>
				<Icon icon="caretRight" />
			</div>
			{children}
		</div>
	);
};

export default TopicsSlider;
