import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
	chipContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		padding: theme.spacing(0, 2.5),
		margin: theme.spacing(-2, 0, 0, -2)
	},
	chipTitle: {
		fontWeight: 400,
		display: 'inline'
	},
	chip: {
		margin: theme.spacing(2, 0, 0, 2),
		backgroundColor: theme.palette.secondary['100'],
		color: theme.palette.secondary.main,
		borderRadius: 6,
		height: 36,
		paddingRight: theme.spacing(0.5)
	},
	chipDeleteIcon: {
		color: theme.palette.secondary.main
	}
}));

export default useStyles;
